import React from 'react';
import {Alert} from "react-bootstrap";
import Utils from "../../../../utils/all";

class AlertInfo extends React.Component {
    constructor(props) {
        super(props);

        this.handlerHide = this.handlerHide.bind(this);

        this.state = {
            show: props.show ? props.show : true,
            message: props.message ? props.message : "",

            hideTime: props.hideTime ? props.hideTime : 4000,
        }
        if(props.hide){
            this.handlerHide( this.state.hideTime);
        }
    }
    handlerHide = (time) =>{
        setTimeout( () => {
            this.setState((state) => {
                // Важно: используем `state` вместо `this.state` при обновлении.
                return {show: false}
            });
        }, time);
    }
    render() {
        const {children} = this.props;
        const type = this.props.type ? this.props.type : "success"
        if(this.state.show) {
            return (
                <Alert variant={type} >
                    {children && Utils.convertToHtml(children)}
                </Alert>
            );
        } else {
            return null;
        }

    }
}

export default AlertInfo;