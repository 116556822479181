"use client";

import { createContext, useContext, useState, useEffect } from "react";
import { useRouter, usePathname } from "next/navigation";
import { api } from "../services/api/Api";
import Cookies from "js-cookie";

interface Props {
    override?: {};
    children?: React.ReactNode;
}

interface IAuthJWTContext {
    auth: any;
    profile: any;
    login: (username: string, password: string) => any;
    logout: () => void;
    register: (
        username: string,
        password: string,
        email: string,
        profile?: any
    ) => Promise<boolean>;
    // redirectToLogin: (pathUrl?: string, urlLogin?: string) => void;
}

const AuthJWTContext = createContext<IAuthJWTContext>({} as IAuthJWTContext);

const AuthJWTProvider: React.FC<Props> = ({ children, override }) => {
    const router = useRouter();
    const pathname = usePathname();
    const [auth, setAuth] = useState(false);
    const [profile, setProfile] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const authorization = Cookies.get("authorization");
        if (!authorization) {
            if (auth) setAuth(false);
            setIsLoading(false);
        } else {
            verifyToken().then((user) => {
                if (user && user.auth) {
                    setAuth(true);
                    setProfile({
                        ...user.profile,
                        group: user.group,
                    });
                    setIsLoading(false);
                } else {
                    setAuth(false);
                    // redirectToLogin(pathname);
                }
            });
        }
    }, []);

    const login = async (username: string, password: string) => {
        try {
            const response = await api.query.post("/auth/login", { username, password });
            if (response) {
                setAuth(true);
                return true;
            } else {
                setAuth(false);
                return false;
            }
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const logout = async () => {
        try {
            await api.query.post("/auth/logout");
            setAuth(false);
            window.location.reload();
        } catch (error) {
            console.error(error);
        }
    };

    const register = async (
        username: string,
        password: string,
        email: string,
        profile: any = {}
    ) => {
        try {
            const response = await api.query.post("/auth/register", {
                username,
                password,
                email,
                profile,
            });
            const { auth } = response;
            if (auth) {
                setAuth(true);
                setProfile({
                    ...response.profile,
                    group: response.group,
                });
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    // const redirectToLogin = (pathUrl = "/", urlLogin = "/auth/login") => {
    //     router.push(
    //         `${urlLogin}?url=${encodeURIComponent(pathUrl)}`
    //     );
    // };

    const verifyToken = async () => {
        try {
            const response = await api.query.get("/auth/verify", null, null, false);
            return response;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const value = {
        auth,
        login,
        logout,
        profile,
        register,
        // redirectToLogin,
    };

    if (isLoading) return null;
    return (
        <AuthJWTContext.Provider value={value}>{children}</AuthJWTContext.Provider>
    );
};

const useAuthJWTContext = () => useContext(AuthJWTContext);

export { AuthJWTContext, AuthJWTProvider, useAuthJWTContext };
