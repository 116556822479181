import axios from 'axios';
import handleErrors from "../_helpers/handlerror";
import Conf from "../../config";

async function getSite(name) {

    const url = `https://cms.bag-travels.ru/api/collections/get/sites`;
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + Conf.apiHostCms.Token
    };
    const query = {
        filter: { title: name },
        limit: 1,
        simple: 1
    };

    return await axios
        .post(url, { ...query }, { headers })
        .then(function (res) {
            return res.data[0];
        })
        .catch(function () {
            return false
        });
}
async function getMailTemplate(name) {

    const url = `https://cms.bag-travels.ru/api/collections/get/mail`;
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + Conf.apiHostCms.Token
    };
    const query = {
        filter: {
            "site.display": name
        },
        limit: 1,
        simple: 1
    };

    return await axios
        .post(url, { ...query }, { headers })
        .then(function (res) {
            return res.data[0];
        })
        .catch(function () {
            return false
        });
}

async function getCollection(name, query) {

    const url = `https://cms.bag-travels.ru/api/collections/get/${name}`;
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + Conf.apiHostCms.Token
    };
    // const query = {
    //     filter: {
    //         "site.display": name
    //     },
    //     limit: 1,
    //     simple: 1
    // };

    return await axios
        .post(url, { ...query }, { headers })
        .then(function (res) {
            return res.data;
        })
        .catch(function () {
            return false
        });
}

async function saveCollection(name, data) {

    const url = `https://cms.bag-travels.ru/api/collections/save/${name}`;
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + Conf.apiHostCms.Token
    };
    const query = {
        data: {
            ...data
        }
    };
    return await axios
        .post(url, { ...query }, { headers })
        .then(handleErrors)
        .then(function (res) {
            return res.data;
        })
        .catch(function () {
            return false
        });

}
export const cmsApi = {
    getMailTemplate,
    getSite,
    getCollection,
    saveCollection
}