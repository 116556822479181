"use client"
import React, { createContext, useContext, useEffect, useState } from 'react';
import { api } from '../services/api/Api';
import { helpers } from '../services/_helpers/Helpers';
import Conf from "../config";

interface ILandingContext {
    config: any;
    landing: any;
    mail: any;
    menu: any;
    notify: any;
    theme: any;
    setConfig?: (item: any) => void;
    setLanding: (item: any) => void;
    setMail?: (item: any) => void;
    setNotify?: (item: any) => void;
    setTheme?: (item: any) => void;
}

const LandingContext = createContext<ILandingContext>({
    config: null,
    landing: null,
    mail: null,
    menu: null,
    notify: null,
    theme: null,
    setConfig: () => { },
    setLanding: () => { },
    setMail: () => { },
    setNotify: () => { },
    setTheme: () => { }
});

interface IProps {
    site?: any;
    children: React.ReactNode;
}

const LandingProvider: React.FC<IProps> = ({ site = null, children }) => {
    const [config, setConfig] = useState<any>(Conf);
    const [landing, setLanding] = useState<any>({
        _id: site.domain._id,
        bgId: site.domain.bgId,
        domain: site.domain.domain
    });
    const [mail, setMail] = useState<any>(site.mail);
    const [menu, setMenu] = useState<any>(site.menu);
    const [notify, setNotify] = useState<any>(site.notify);
    const [theme, setTheme] = useState<any>(site.theme);

    const value = { config, landing, mail, menu, notify, theme, setLanding, setNotify };
    return <LandingContext.Provider value={value}>{children}</LandingContext.Provider>
}

const useLandingContext = () => useContext(LandingContext)

export { LandingContext, LandingProvider, useLandingContext }