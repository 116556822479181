"use client"
import React, { useState, useEffect } from "react";
import { helpers } from "../../services/_helpers/Helpers";

interface ComponentProps {
  alt?: string;
  //cockpit link foramt {path, meta}
  asset?: any;
  //enable/disable parent container
  container?: boolean;
  //className parent container
  containerClassName?: string;
  //className img
  imageClassName?: string;
  //full image url
  src?: string;
  //cockpit url true: /storage/uploads; false: https://content.bagtravels.ru
  storage?: boolean;
  //true: https://content.bagtravels.ru; false: https://bagtravels.ru
  cdn?: boolean;
}

export const ImageSrc: React.FC<ComponentProps> = ({
  alt = "",
  //cockpit link foramt {path, meta}
  asset = null,
  container = true,
  containerClassName = "",
  imageClassName = "zoom img-card-ratio",
  src = "",
  storage = false,
  cdn = false,
}) => {
  const [imgSrc, setImgSrc] = useState<any>(src);
  const [original, setOriginal] = useState(false);

  let imageSrc: any = null;

  // let original = null;
  // let webP = null;

  useEffect(() => {
    if (asset) {
      imageSrc = helpers.image.getUrlImage(asset, storage, cdn);
      alt = asset?.meta?.title || "";
      //@ts-ignore
      setImgSrc({
        ...imageSrc,
        src: imageSrc.webP || imageSrc.original || helpers.constant.imageFallback,
      });
    } else {
      setImgSrc({ src: src });
    }
  }, []);

  const handleError = () => {
    const fallbackSrc = helpers.constant.imageFallback;
    if (!original && asset) {
      setOriginal(true);
      //@ts-ignore
      setImgSrc({
        ...imgSrc,
        src: imgSrc.original,
      });
    } else {
      //@ts-ignore
      setImgSrc({
        ...imgSrc,
        src: fallbackSrc,
      });
    }
  };

  const renderImage = () => {
    alt = asset ? asset?.meta?.title || "" : "";
    const imgProps = {
      onError: handleError,
      className: imageClassName,
      src: imgSrc.src,
      alt,
    };

    if (container) {
      return (
        <div className={containerClassName}>
          <img {...imgProps} />
        </div>
      );
    } else {
      return <img {...imgProps} />;
    }
  };

  if (!imgSrc) return null;
  return renderImage();
};
