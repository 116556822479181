"use client";

import { useEffect, useState } from "react";

// Создаем тип для состояния
interface WindowSize {
    width: number;
    height: number;
    isMobile: boolean;
}

const useResize = (): WindowSize => {
    // Состояние для хранения размера окна
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: typeof window !== "undefined" ? window.innerWidth : 0,
        height: typeof window !== "undefined" ? window.innerHeight : 0,
        isMobile: typeof window !== "undefined" ? window.innerWidth < 768 : false,
    });

    useEffect(() => {
        // Обработчик изменения размера окна
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                isMobile: window.innerWidth < 768, // Мобильное устройство, если ширина окна меньше 768
            });
        };

        // Добавляем слушатель события resize
        window.addEventListener("resize", handleResize);

        // Инициализация при монтировании компонента
        handleResize();

        // Убираем слушатель при размонтировании
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return windowSize;
};

export default useResize;
