import Conf from "../config";
import convert from "htmr";

let Utils = {
    isMobileSize: function (width) {
        if (width > 0 && width < 768) {
            return true;
        } else {
            return false
        }
    },
    createStringFromTemplate: function (template, variables) {
        return template.replace(new RegExp("\{([^\{]+)\}", "g"), function (_unused, varName) {
            return variables[varName];
        });
    },
    //Проверка номера телефона
    isPhoneNumber: function (str) {
        var patt = new RegExp(/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm);
        return patt.test(str);
    },
    //Оставляет только буквы
    tgtrimm: function (str) {
        var ars = str.replace(/[^a-zA-ZА-Яа-яЁё]/gi, '').replace(/\s+/gi, ', ');
        return ars;
    },
    //Mongoose ObjectId Check
    checkObjId: function (id) {
        let hex = /[0-9A-Fa-f]{6}/g;
        id = (hex.test(id)) ? ObjectId(id) : id;
        return id;
    },
    //JavaScript — Find key for value in an object
    findKey: function (obj, value) {
        let key = null;

        for (let prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                if (obj[prop] === value) {
                    key = prop;
                }
            }
        }

        return key;
    },
    //This will return true for This will return true for undefined  // Because undefined == null
    //
    // null
    //
    // []
    //
    // ""
    isEmpty: function (value) {
        return (value == null || value.length === 0 || value === undefined);
    },
    randKey: function () {
        return Math.random() + Math.random() / Math.random();
    },
    //Обоезка текста в процентах
    cutPercentText: function (text, percent) {
        let count_text = text.length;
        let view_text = Math.round(count_text / 3);
        let cut_text = text.substr(0, view_text)
        let collapse_text = text.substr(view_text);
        let textObj = {
            cut_text: cut_text,
            collapse_text: collapse_text
        }
        return (textObj);
    },
    //Случайная строка
    randStr: function (length) {
        let radom13chars = function () {
            return Math.random().toString(16).substring(2, 15)
        }
        let loops = Math.ceil(length / 13)
        return new Array(loops).fill(radom13chars).reduce((string, func) => {
            return string + func()
        }, '').substring(0, length)
    },
    //Случайное целое число от min до max
    randomInt: function (min, max) {
        return min + Math.floor((max - min) * Math.random());
    },
    //Количество ночей
    days_between: function (date1, date2) {

        // The number of milliseconds in one day
        const ONE_DAY = 1000 * 60 * 60 * 24;

        // Calculate the difference in milliseconds
        const differenceMs = Math.abs(date1 - date2);

        // Convert back to days and return
        return Math.round(differenceMs / ONE_DAY);

    },
    cut_text: function (text, length) {
        let cutText = text.substr(0, length);
        return cutText;
    },
    // Определение кирилицы
    /*
    "абв" - false
    "abc123" - true
     */
    isKyr: function (str) {
        return /^\s*(\w+)\s*$/.test(str);
    },
    //Если с английского на русский, то передаём вторым параметром true.
    latin_to_rus: function (str) {

        var ru = {
            'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
            'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
            'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
            'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
            'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
            'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya'
        }, n_str = [];

        str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');

        for (var i = 0; i < str.length; ++i) {
            n_str.push(
                ru[str[i]]
                || ru[str[i].toLowerCase()] == undefined && str[i]
                || ru[str[i].toLowerCase()].replace(/^(.)/, function (match) { return match.toUpperCase() })
            );
        }

        return n_str.join('');
    },
    // Switch Methods
    //Уровень Отеля
    getLevel: function (level) {
        let typeLevel;
        switch (level) {
            case "1":
                typeLevel = "Эконом (1-2*)";
                break;
            case "2":
                typeLevel = "Средний (3*)";
                break;
            case "3":
                typeLevel = "Высокий (4-5*)";
                break;
            case "4":
                typeLevel = "VIP";
                break;
        }
        return typeLevel;
    },
    getImageStyleUrl: function (style, image) {
        if (image && image.styles !== undefined) {
            const img = image.styles.find(imgStyle => imgStyle.style === style);

            if (img && img.path) {
                return Conf.imageHostNoStorage + img.path;
            } else {
                return Conf.siteUrl + "/dummy/600x400.png";
            }
        }
        if (image && image.path) {
            return Conf.imageHostNoStorage + image.path;
        }
        return Conf.siteUrl + "/dummy/600x400.png";
    },
    hexToRgbA: function (hex, opacity = 1) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
        }
        throw new Error('Bad Hex');
    },
    convertToHtml(html) {
        if (html) {
            return convert(html);
        } else {
            return "";
        }
    }
}

export default Utils;