const token = require('basic-auth-token');
const Conf = {
    siteUrl: "https://cms.bag-travels.ru",
    headerConf: {
        "phones": ['+7(800) 511-35-15 (бесплатно)', '+7(495) 266-02-15', '+7(988) 410-10-30', '+7(978) 01-01-260'],
    },
    apiHostCms: {
        "Host": "https://cms.bag-travels.ru",
        "Token": "d0e5ac6519313ecd9373551d78cd8f",
        //token("admin", "6?[y*)09370!$E9HV")
    },
    invoice: 7000,
    imageHost: "https://cms.bag-travels.ru/storage/uploads",
    imageHostNoStorage: "https://cms.bag-travels.ru"
};

export default Conf;
