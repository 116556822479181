"use client"
import React, { FC, useEffect, useState } from "react";
import { ReactSVG } from 'react-svg';
import { useLandingContext } from "../../../context/LandingProvider";
import { helpers } from "../../../services/_helpers/Helpers";

interface IProps {
    small?: boolean;
    type?: "logo" | "spinner" | "svg";
    svgStyle?: any;
    center?: boolean;
    delay?: number;
};

const defaultProps = { center: true, small: false, type: "logo", svgStyle: { fill: "#10a399", height: "30%", width: "30%" }, delay: 0 };

const PreLoading: FC<IProps> = (props) => {
    const { small, type, svgStyle, center, delay } = { ...defaultProps, ...props };
    const { theme } = useLandingContext();
    const [preLoading, setPreLoading] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setPreLoading(true);
        }, delay);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [delay]);


    if (!preLoading) {
        return <></>;
    }

    switch (type) {
        case "logo":
            return (
                <div style={{ margin: small ? "0%" : "10%", textAlign: 'center' }}>
                    <img className="logo-rotate-vert-center" src={helpers.constant.cms.storage + theme.logo.path} />
                    <h3>Загрузка...</h3>
                </div>
            );
            break;
        // case "spinner":
        //     return (
        //         <div style={{ textAlign: 'center' }}>
        //             <Spinner
        //                 as="span"
        //                 animation="grow"
        //                 size="sm"
        //                 role="status"
        //                 aria-hidden="true"
        //             />
        //         </div>
        //     )
        //     break;

        case "svg":
            return (
                <div className={center ? "m-auto my-5" : ""} style={{ width: svgStyle.width, fill: svgStyle.fill }}>

                    <ReactSVG
                        src="/icons/loaders/spinning-bubbles.svg"
                    />
                </div >
            )
            break;
        default:
            return null;
            break;
    }
};

export default PreLoading;

